@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    body{
        @apply font-[Poppins];
        overflow-x: hidden;
        background-color: #5a005b;
        @apply text-white;
    }

    button{
        @apply border border-black py-1 rounded-full;
    }
}
